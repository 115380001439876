import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);
// router
import router from "./router";
app.use(router);
// AntDesign
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
app.use(Antd);

// Axios
import axios from "axios";
import VueAxios from "vue-axios";
import ui from "./userinfo.js";
import { message } from "ant-design-vue";

let axios_instance = axios.create({
  baseURL: "http://8.138.115.118:9999",
});
app.use(VueAxios, { axios: axios_instance });
app.axios.defaults.withCredentials = true;
app.axios.interceptors.request.use((req) => {
  if (ui.get()) {
    req.headers = Object.assign(req.headers, {
      "access-token": ui.get().access_token,
    });
  }
  req.headers = Object.assign(req.headers, {
    "local-serial": ui.get_uuid(),
  });
  return req;
});
app.axios.interceptors.response.use(
  (r) => {
    if (r.data.status == 1) {
      return r;
    } else {
      ui.remove();
      return r;
    }
  },
  (error) => {
    message.error(error.message);
  }
);

axios_instance
  .post("/user/login", { account: "admin", password: "123654" })
  .then((r) => {
    if (r.data.status == 1) {
      ui.is_login.value = true;
      ui.set(r.data.data);
    }
  });

app.provide("axios", app.config.globalProperties.axios);
app.mount("#app");
