<template>
  <div id="app">
    <a-flex gap="middle" align="center" justify="space-around" :style="{
      width: '100%',
      height: '120px',
      borderRadius: '6px',
      border: '1px solid #40a9ff',
    }">
      <a-button type="primary" @click="get_chart">click me!</a-button>
    </a-flex>
    <div id="echart" class="chart"></div>
  </div>
</template>


<script setup>
import * as echarts from "echarts";

import { ref, inject, onMounted } from "vue";
const axios = inject("axios"); // inject axios
let myChart;

let raw = ref([]);

const chart_data = ref({
  title: {
    text: 'Formula  = Meal*78.5% + Oil*18.5% - SB*36.7433*RMB*1.03*1.13',
  },
  axisPointer: {
    link: { xAxisIndex: 'all' },
    label: {
      backgroundColor: '#777'
    }
  },
  animationDuration: 3000,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    formatter: (params,) => {
      console.log("Params", params)
      // console.log("Ticket", ticket)
      // render("hello", "world")
      let html_str = params.map((p) => {
        if (p.value) {
          let pos = raw.value.date.findIndex((d) => d === p.axisValue)
          let sb
          let sbm
          let sbo
          let rmb
          if (p.seriesName === "Crush Margin 202311") {
            sb = "CBOT_SB_202311:" + raw.value["CBOT_SB_202311"][pos]
            sbm = "DCE_SBM_202401:" + raw.value["DCE_SBM_202401"][pos]
            sbo = "DCE_SBO_202401:" + raw.value["DCE_SBO_202401"][pos]
            rmb = "RMB_CNH" + raw.value["RMB_CNH"][pos]
          }
          if (p.seriesName === "Crush Margin 202403") {
            sb = "CBOT_SB_202403:" + raw.value["CBOT_SB_202403"][pos]
            sbm = "DCE_SBM_202405:" + raw.value["DCE_SBM_202405"][pos]
            sbo = "DCE_SBO_202405:" + raw.value["DCE_SBO_202405"][pos]
            rmb = "RMB_CNH:" + raw.value["RMB_CNH"][pos]
          }
          if (p.seriesName === "Crush Margin 202407") {
            sb = "CBOT_SB_202407:" + raw.value["CBOT_SB_202407"][pos]
            sbm = "DCE_SBM_202409:" + raw.value["DCE_SBM_202409"][pos]
            sbo = "DCE_SBO_202409:" + raw.value["DCE_SBO_202409"][pos]
            rmb = "RMB_CNH:" + raw.value["RMB_CNH"][pos]
          }
          if (p.seriesName === "Crush Margin 202411") {
            sb = "CBOT_SB_202411:" + raw.value["CBOT_SB_202411"][pos]
            sbm = "DCE_SBM_202501:" + raw.value["DCE_SBM_202501"][pos]
            sbo = "DCE_SBO_202501:" + raw.value["DCE_SBO_202501"][pos]
            rmb = "RMB_CNH:" + raw.value["RMB_CNH"][pos]
          }
          return `<div>
                    <h3>Series:${p.seriesName}</h3>
                    <p>Crush:${p.value}</p>
                    <p>${sb}/${sbm}/${sbo}/${rmb}</p>
                  </div>`
        } else {
          return "<div></div>"
        }
      }).join("")
      return "<div>" + html_str + "</div>"
    }
  },
  legend: {
    bottom: 50,
    left: 'center',
    data: []
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: [],
  dataZoom: [
    {
      show: true,
    },
  ],
});
const get_chart = () => {
  axios.post("/api/get_local_data", {
    symbols: [
      'RMB_CNH',
      'CBOT_SB_202311', 'DCE_SBM_202401', 'DCE_SBO_202401',
      'CBOT_SB_202403', 'DCE_SBM_202405', 'DCE_SBO_202405',
      'CBOT_SB_202407', 'DCE_SBM_202409', 'DCE_SBO_202409',
      'CBOT_SB_202411', 'DCE_SBM_202501', 'DCE_SBO_202501',
    ], start: "2023-03-26"
  }).then((r) => {
    raw.value = r.data.data
    let d = r.data.data
    let crush_margin_202311 = d["CBOT_SB_202311"].map((s, idx) => {
      if (d["CBOT_SB_202311"][idx] && d["DCE_SBM_202401"][idx] && d["DCE_SBO_202401"][idx] && d["RMB_CNH"][idx]) {
        return Math.round(d["DCE_SBM_202401"][idx] * 0.785 + d["DCE_SBO_202401"][idx] * 0.185 - d["CBOT_SB_202311"][idx] * 0.367433 * d["RMB_CNH"][idx] * 1.03 * 1.13)
      } else {
        return null
      }
    })
    let crush_margin_202403 = d["CBOT_SB_202403"].map((s, idx) => {
      if (d["CBOT_SB_202403"][idx] && d["DCE_SBM_202405"][idx] && d["DCE_SBO_202405"][idx] && d["RMB_CNH"][idx]) {
        return Math.round(d["DCE_SBM_202405"][idx] * 0.785 + d["DCE_SBO_202405"][idx] * 0.185 - d["CBOT_SB_202403"][idx] * 0.367433 * d["RMB_CNH"][idx] * 1.03 * 1.13)
      } else {
        return null
      }
    })
    let crush_margin_202407 = d["CBOT_SB_202407"].map((s, idx) => {
      if (d["CBOT_SB_202407"][idx] && d["DCE_SBM_202409"][idx] && d["DCE_SBO_202409"][idx] && d["RMB_CNH"][idx]) {
        return Math.round(d["DCE_SBM_202409"][idx] * 0.785 + d["DCE_SBO_202409"][idx] * 0.185 - d["CBOT_SB_202407"][idx] * 0.367433 * d["RMB_CNH"][idx] * 1.03 * 1.13)
      } else {
        return null
      }
    })
    let crush_margin_202411 = d["CBOT_SB_202411"].map((s, idx) => {
      if (d["CBOT_SB_202411"][idx] && d["DCE_SBM_202501"][idx] && d["DCE_SBO_202501"][idx] && d["RMB_CNH"][idx]) {
        return Math.round(d["DCE_SBM_202501"][idx] * 0.785 + d["DCE_SBO_202501"][idx] * 0.185 - d["CBOT_SB_202411"][idx] * 0.367433 * d["RMB_CNH"][idx] * 1.03 * 1.13)
      } else {
        return null
      }
    })
    chart_data.value.legend.data = ['Crush Margin 202311', 'Crush Margin 202403', 'Crush Margin 202407', 'Crush Margin 202411']
    chart_data.value.series = [
      {
        name: 'Crush Margin 202311',
        type: 'line',
        data: crush_margin_202311
      },
      {
        name: 'Crush Margin 202403',
        type: 'line',
        data: crush_margin_202403
      },
      {
        name: 'Crush Margin 202407',
        type: 'line',
        data: crush_margin_202407
      },
      {
        name: 'Crush Margin 202411',
        type: 'line',
        data: crush_margin_202411
      }
    ]
    chart_data.value.xAxis.data = d["date"]
    myChart.setOption(chart_data.value);
  });
};
onMounted(() => {
  myChart = echarts.init(document.getElementById("echart"))
});
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .chart {
    display: block;
    margin: 20px auto;
    width: 90%;
    height: 500px;
  }
}
</style>
