import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";

function setup() {
  const is_login = ref(false);
  const set = (ui) => {
    localStorage.setItem("tislab_back", JSON.stringify(ui));
  };
  const get = () => {
    return JSON.parse(localStorage.getItem("tislab_back"));
  };
  const remove = () => {
    is_login.value = false;
    localStorage.removeItem("tislab_back");
    localStorage.removeItem("tislab_back_uuid");
  };
  const genertate_uuid = () => {
    var u = uuidv4();
    localStorage.setItem("tislab_back_uuid", u);
    return u;
  };
  const get_uuid = () => {
    var u = localStorage.getItem("tislab_back_uuid");
    if (u == null) {
      u = genertate_uuid();
    }
    return u;
  };

  return {
    is_login,
    set,
    get,
    remove,
    get_uuid,
    genertate_uuid,
  };
}

const ui = setup();
if (ui.get() !== null) {
  let user_info = ui.get();
  if (user_info) {
    ui.is_login.value = true;
  }
}
export default ui;
